//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaHeader from '@/components/SanghaHeader.vue';

export default {
  name: 'PageNotFound',
  data() {
    return {
      state: 'default',
      email: null,
      code: null,
      errors: {
        email: false,
      },
    };
  },
  components: {
    SanghaHeader,
  },
  methods: {},
  computed: {},
  mounted() {},
};
